//------------------------------------------------------------base styles
:root {
	--full-screen: calc(var(--vh, 1vh) * 100);
}

html {
	height: 100%;
	font: 400 calc(100vw / 1920 * 10)/1.33 $font_1;
	scroll-behavior: smooth;
	user-select: none;
	@include bp($point_2) {
		font-size: 10px;
	}
}

body {
	position: relative;

	height: 100%;
	font-size: $font_size_base;

	color: $color_text_base;
	-webkit-text-size-adjust: none;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background: $body_bg;

	@supports (display: grid) {
		display: block;
	}

	&.active {
		overflow: hidden;
		padding-right: 1.4rem;
		@include bp($point_2) {
			padding-right: 0;
		}
	}

	@include bp($point_2) {
		font-size: 1.8rem;
	}
}

.wrapper {
	position: relative;

	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	height: auto !important;

	overflow-x: hidden;
}

.base {
	width: 100%;
	position: relative;
	padding: $header 0 0;

	flex: 1 0 auto;
	@include bp($point_2) {
		padding: 0;
	}
	
}

#viewport {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	@include bp($point_2) {
		// position: static;
		// overflow: auto;
	}
}
.smooth_scrolls {
  position: absolute;  
  overflow: hidden;
  width: 100%;
	padding: $header 0 0;
	@include bp($point_2) {
		padding: $header_mob 0 0;
		display: flex;
		flex-direction: column;
		// position: static;
	}
}
a {
	text-decoration: none;
	color: $color_link_base;

	&:hover,
	&:focus {
		text-decoration: none;
	}

}

.widget_list {
	padding: 0 1rem;
	li {
		@include link;
		padding: 0!important;
		margin-bottom: 1rem;
		border-bottom: .1rem solid rgba(255, 255, 255, 0.233);
		&:last-child {
			margin-bottom: 0;
		}
		&:before {
			border-bottom: .1rem solid #C91313;
			bottom: -.1rem;
		}
	}
	a {
		text-transform: uppercase!important;
		font-family: $font_1!important;
		text-decoration: none!important;
		transition: all .3s ease;
		&:hover {
			opacity: .8;
		}
	}
}

//------------------------------------------------------------base styles###
