.popup_price {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include z-index(popup);
	@include flex-center;
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease;
	@include bp($point_2) {
		padding: 1.5rem;
	}
	&.active {
		opacity: 1;
		visibility: visible;
		.popup_price_main {
			transform: translateY(0);
		}
	}
}
.popup_price_bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(37, 37, 37, 0.8);
}
.popup_price_main {
	background-color: $white;
	padding: 6rem 12rem 8rem;
	max-width: 70rem;
	width: 100%;
	text-align: center;
	position: relative;
	transition: all .3s ease;
	transform: translateY(-5rem);
	@include bp($point_2) {
		padding: 3rem 1.5rem;
	}
	h3 {
		margin-bottom: 1rem;
	}
	p {
		color: $gray;
	}
}
.popup_price_content {
	margin-bottom: 4rem;
	@include bp($point_2) {
		margin-bottom: 2rem;
	}
}
.popup_price_link {
	display: flex;
	width: 100%;
	a {
		@include btn-button;
		padding: 2rem 15.8rem;
		font-size: $s;
		width: 100%;
		border-radius: 1rem;
		@include bp($point_2) {
			padding: 1.5rem 2rem!important;
		}
	}
}





.burger {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include z-index(popup);
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease;
	&.active {
		opacity: 1;
		visibility: visible;
		.burger_main {
			transform: translateX(0);
		}
	}
}
.burger_bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(37, 37, 37, 0.8);
}
.burger_main {
	max-width: 34rem;
	background-color: $white;
	position: relative;
	height: 100%;
	overflow-y: auto;
	padding: 2.5rem 1.5rem;
	transition: all .3s ease;
	transform: translateX(-5rem);
}
.burger_close {
	position: absolute;
	top: 2.9rem;
	right: 1.5rem;
	cursor: pointer;
}
.icon {
}
.icon-close {
}
.burger_logo {
	width: 5rem;
	margin-bottom: 3.2rem;
}
.cover_img {
}
.burger_menu {
	margin-bottom: 5.5rem;
	ul {
		li {
			a {
				color: $dark;
				font-size: 2.5rem;
				font-family: $font_2;
				@include link;
				&:hover {
					color: $orange;
				}
			}
			&:not(:last-child) {
				margin-bottom: 1.4rem;
			}
		}
	}
}
.burger_social {
	@include flex-start;
	margin-bottom: 2.5rem;
	li {
		@include el-scale;
		a {
			@include flex-center;
		}
		&:not(:last-child) {
			margin-right: 2.5rem;
			a {
				color: #009ED0;
			}
		}
		&:last-child {
			a {
				color: #00AF27;
			}
		}
	}
}
.icon-wa {
}
.icon-tg {
}
.burger_number {
	li {
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
		a {
			color: $dark;
			font-family: $font_2;
			font-size: $m;
		}
	}
}
.popup_form {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include z-index(popup);
	@include flex-center;
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease;
	@include bp($point_2) {
		padding: 1.5rem;
	}
	&.active {
		opacity: 1;
		visibility: visible;
		.popup_form_main {
			transform: translateY(0);
		}
	}
}
.popup_form_bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(37, 37, 37, 0.8);
}
.popup_form_main {
	position: relative;
	background-color: $white;
	padding: 6rem 12.1rem 8rem 12.1rem;
	max-width: 70rem;
	transition: all .3s ease;
	transform: translateY(-5rem);
	@include bp($point_2) {
		padding: 4rem 1.5rem;
	}
}
.popup_form_close {
	stroke: $orange;
	position: absolute;
	top: 2rem;
	right: 2rem;
	@include bp($point_2) {
		top: 1.5rem;
		right: 1.5rem;
	}
	button {
		@include flex-center;
		cursor: pointer;
		transition: all .3s ease;
		&:hover {
			opacity: .5;
		}
	}
}
.popup_form_head {
	text-align: center;
	h3 {
		margin-bottom: 1rem;
		@include bp($point_2) {
			font-size: 2.2rem;
		}
	}
	margin-bottom: 4rem;
	@include bp($point_2) {
		margin-bottom: 2rem;
	}
	p {
		color: $gray;
		@include bp($point_2) {
			font-size: 1.6rem;
		}
	}
}
.popup_form_txt {
}
.popup_form_action {
}
.popup_form_input {
	margin-bottom: 1rem;
	input {
		@include app;
		font-size: $s;
		color: $dark;
		background-color: #F4F4F4;
		border-radius: 1rem;
		width: 100%;
		padding: 2.2rem;
		@include bp($point_2) {
			padding: 1.5rem 2rem;
			font-size: 1.6rem;
		}
		&::-webkit-input-placeholder {
					color: $dark;
					transition: all .3s ease;
		}
		&:-ms-input-placeholder {
					color: $dark;
					transition: all .3s ease;
		}
		&:focus {
			&::-webkit-input-placeholder {
				opacity: .5;
			}
			&:-ms-input-placeholder {
				opacity: .5;
			}
		}
	}
}
.form_input_end {
}
.popup_form_btn {
	margin-bottom: 2.5rem;
	button {
		@include btn-button;
		padding: 2rem 5rem;
		width: 100%;
		font-size: $m;
		@include bp($point_2) {
			padding: 1.5rem 3rem!important;
		}
	}
}
.popup_form_check {
	input {
		display: none;
		&:checked + label:after {
			transform: scale(1);
		}
	}
	label {
		position: relative;
		display: block;
		font-size: $xs;
		max-width: 38.6rem;
		padding-left: 4rem;
		@include bp($point_2) {
			font-size: 1.2rem;
		}
		cursor: pointer;
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			background-color: #F4F4F4;
			content: '';
			border-radius: 0.5rem;
			width: 2.5rem;
			height: 2.5rem;
		}
		&:after {
			content: '';
			position: absolute;
			top: .7rem;
			left: .4rem;
			width: 1.7rem;
			height: 1.2rem;
			background-image: url('../../i/checkbox.svg');
			background-size: 1.7rem 1.2rem;
			background-repeat: no-repeat;
			background-position: center center;
			transform: scale(0);
			transition: all .3s ease;
		}
		a {
			color: $dark;
			border-bottom: .1rem solid $dark;
			@include bp($point_2) {
				font-size: 1.2rem;
			}
		}
	}
}