.hero {
	position: relative;
	color: $white;
}
.trigger_btn {
	button {
		@include btn-button;
	}
}
.hero_img_bg {
	position: absolute;
	top: - $header;
	@include z-index(el);
	@include bp($point_2) {
		top: - $header_mob;
		width: 100%;
		height: 100%;
	}
	img {
		@include bp($point_2) {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
.section_in {
}
.hero_main {
	padding: 12.4rem 0 28.2rem 0;
	max-width: 67.9rem;
	margin-bottom: 5.8rem;
	@include bp($point_2) {
		padding: 5.7rem 0 16.8rem 0;
	}
	h1 {
		margin-bottom: 6rem;
		@include bp($point_2) {
			margin-bottom: 3rem;
		}
	}
}
.hero_title {
}
.hero_btn_action {
	display: inline-flex;
}
.service {
}
.service_main {
	margin-bottom: 14rem;
	position: relative;
	padding-bottom: 3.5rem;
	@include bp($point_2) {
		margin-bottom: 7rem;
	}
}
.service_wrapper {
	@include bp($point_2, $direction:min) {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(29rem, 1fr));
		grid-gap: 8.3rem;
	}
	@include bp($point_2) {
		display: flex;
		grid-gap: none;
		grid-template-columns: none;
	}
}

.service_list {
	@include bp($point_2) {
		max-width: 29.3rem;
	}
}
.service_item.swiper-slide {
	flex-shrink: 1!important;
	@include bp($point_2) {
		flex-shrink: 0!important;
		text-align: center;
	}
}
.service_item_icon {
	width: 6.7rem;
	margin-bottom: 2.4rem;
	@include flex-center;
	@include bp($point_2) {
		margin: 0 auto;
		margin-bottom: 2.5rem;
	}
}

.service_pag, .reviews_slide_pug {
	display: none;
	@include bp($point_2) {
		@include flex-center;
		bottom: 0!important;
		position: absolute;
	}
	.swiper-pagination-bullet {
		width: .9rem;
		height: .9rem;
		transition: all .3s ease;
		background-color: #D1D1D1;
		opacity: 1;
		margin: 0;
		&:not(:last-child) {
			margin-right: 1rem;
		}
		&-active {
			background-color: $orange;
			width: 1.4rem;
			height: 1.4rem;
		}
	}
}
.service_item_content {
}
.service_item_title {
	h3 {
		font-size: 2.2rem;
		font-family: $font_2;
		margin-bottom: 2rem;
		@include bp($point_2) {
			margin-bottom: 1rem;
		}
	}
}
.service_item_txt {
	p {
		color: $gray;
		font-size: $s;
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}
.action {
	position: relative;
}
.action_main {
	@include flex-start;
	align-items: flex-start;
	margin-bottom: 15rem;
	@include bp($point_2) {
		padding-top: 34.3rem;
		margin-bottom: 8rem;
	}
}
.action_main_img {
	min-width: 46rem;
	@include flex-center;
	margin-right: 14rem;
	border-bottom-right-radius: 12.5rem;
	overflow: hidden;
	@include bp($point_2) {
		position: absolute;
		left: 0;
		top: 0;
		min-width: auto;
		margin-right: 0;
		width: 28.7rem;
	}
}
.cover_img {
}
.action_content {
	.action_title {
		span {
			@include bp($point_2) {
				display: none;
			}
		}
	}
}

.action_content_txt {
	margin-bottom: 4rem;

	p {


		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}
.action_list {
	li {

		position: relative;
		padding-left: 5.3rem;
		@include bp($point_2) {
			padding-left: 3.4rem;
			font-size: 1.8rem;
		}
			&:before {
				position: absolute;
				top: 50%;
				left: 0;
				width: 2.8rem;
				height: 2.9rem;
				border-radius: 100%;
				content: '';
				background-image: url('../../i/check.svg');
				transform: translateY(-50%);
				background-position: center center;
				background-size: 100%;
				@include bp($point_2) {
					width: 2.4rem;
					height: 2.4rem;
					top: 0rem;
					transform: translateY(0);
				}
			}
		&:not(:last-child) {
			margin-bottom: 2.5rem;
			a {
				border-bottom: .1rem solid $orange;
				&:hover {
					border-bottom: .1rem solid $trans;
					color: $dark;
				}
			}
		}
		a {
			color: $orange;
			font-family: $font_2;
			font-weight: 500;
			@include bp($point_2) {
				font-size: 1.8rem;
			}
		}
	}
}
.sale {
	background-color: #414141;
	color: $white;
	@include bp($point_2) {
		// position: relative;
		margin-bottom: 25.2rem;
	}
}
.sale_main {
	padding: 11.6rem 0 0;
	@include flex-start;
	align-items: flex-start;
	margin-bottom: 26.2rem;
	@include bp($point_2) {
		flex-direction: column;
		padding: 6rem 0 21.5rem 0;
		margin-bottom: 0;
		position: relative;
	}
}
.sale_content {
	max-width: 70rem;
	margin-right: 13.2rem;
	@include bp($point_2) {
		margin-right: 0;
		margin-bottom: 0;
	}
}
.sale_title {
}
.sale_content_txt {
	margin-bottom: 5.2rem;
	@include bp($point_2) {
		margin-bottom: 4.5rem;
	}
	p {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}
.sale_content_btn {
}
.trigger_btn {
}
.sale_img {
	border-bottom-left-radius: 12.5rem;
	@include flex-center;
	overflow: hidden;
	margin-bottom: -13.2rem;
	@include bp($point_2) {
		position: absolute;
		margin-bottom: 0;
		bottom: -18.2rem;
		right: -1.5rem;
		max-width: 28.7rem;
	}
}
.price {
	.section_in {
		@include bp($point_2) {
			padding: 0;
		}
	}
}
.price_main {
	margin-bottom: 18rem;
	@include bp($point_2) {
		margin-bottom: 8rem;
	}
}
.price_title {
	text-align: center;
	margin-bottom: 7rem;
	@include bp($point_2) {
		text-align: left;
		margin-bottom: 3.5rem;
		max-width: 24rem;
		padding: 0 1.5rem;
	}
}
.price_list {
	margin-bottom: 7rem;
	li {
		@include flex-space;
		padding: 2.2rem 15rem;
		font-family: $font_2;
		position: relative;
		@include bp($point_2) {
			padding: 1.8rem 1.5rem;
			font-size: 1.6rem;
		}

		&:nth-child(2n + 1) {
			background-color: #F4F4F4;
		}
	}
}
.price_item {
}
.price_item_name {
}
.price_item_total {
	position: relative;
	@include flex-start;
	&:after {
		content: 'руб.';
		padding-left: 0.3rem;
	}
}
.price_button_row {
	@include flex-center;
	flex-wrap: wrap;
	@include bp($point_2) {
		padding: 0 1.5rem;
	}
}
.price_button_action {
	margin-right: 2rem;
	@include bp($point_2) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 2rem;
	}
	button {
		background-color: $trans;
		color: $orange;
		border: .1rem solid $orange;
		padding: 2.4rem 7.3rem;
		font-size: 2.2rem;
		border-radius: 1rem;
		font-family: $font_2;
		font-weight: 500;
		@include bp($point_2) {
			width: 100%;
		}
		&:hover {
			color: $white
		}
	}
}
.price_button_download {
	@include bp($point_2) {
		width: 100%
	}
	button {
		@include btn-button;
		padding: 2.4rem 7.3rem;
		font-size: 2.2rem;
		border-radius: 1rem;
		font-family: $font_2;
		font-weight: 500;
		@include bp($point_2) {
			width: 100%;
		}
	}
}
.brands {
}
.brands_main {
	margin-bottom: 22rem;
	@include bp($point_2) {
		margin-bottom: 7.7rem;
	}
}
.brands_title {
	text-align: center;
	margin-bottom: 9rem;
	@include bp($point_2) {
		text-align: left;
		margin-bottom: 5.4rem;
	}
}
.brands_list {
	@include flex-space;
	flex-wrap: wrap;
	flex-direction: row;
	opacity: .5;
	@include bp($point_2) {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(44%, 1fr));
		grid-gap: 3.5rem;
		opacity: 1;
		justify-content: center;
	}
	li {
		&:last-child {
			@include bp($point_2) {
				display: none;
			}
		}
 		&:not(:last-child) {
			margin-right: 5rem;
			@include bp($point_2) {
				margin-right: 0;
			}
		}
	}
}
.brands_item {
	max-width: 30.9rem;
	@include bp($point_2) {
		max-width: 100%;
	}
}
.reviews_title {
	text-align: center;
	margin-bottom: 8rem;
	@include bp($point_2) {
		text-align: left;
		margin-bottom: 4.5rem;
	}
}
.reviews {
	position: relative;
}
.reviews_main {
	margin-bottom: 18rem;
	@include bp($point_2) {
		margin-bottom: 8rem;
	}
}
.reviews_slider_w {
	@include bp($point_2) {
		padding-bottom: 3.5rem;
		position: relative;
	}
	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 70%;
		height: 70%;
		content: '';
		background-color: rgba(0, 0, 0, 0.07);
		filter: blur(5rem);
	}
}
.reviews_slide_nav_wrapper {
	position: relative;
	@include bp($point_2) {
		position: static
	}
}
.reviews_slide_nav  {
	@include flex-space;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	pointer-events: none;
	@include bp($point_2) {
		display: none;
	}
	.swiper-button-disabled {
		background-color: #EEEEEE;
		color: $orange;
	}
}
.reviews_slide_prev, .reviews_slide_next {
	width: 4.7rem;
	height: 4.5rem;
	@include flex-center;
	box-shadow: 0 .4rem 4rem rgba(0, 0, 0, 0.07);
	border-radius: .5rem;
	pointer-events: all;
	cursor: pointer;
	background-color: $orange;
	color: $white;
	transition: all .3s ease;
}
.reviews_slider {
	padding-bottom: 2rem!important;
	max-width: 124.5rem;
	.swiper-slide-active {
		.reviews_slide_quote {
			img {
				opacity: 1;
			}
		}
	}
}
.swiper {
}
.swiper-wrapper {
}
.swiper-slide {
}
.reviews_slide {
	background: #414141;
	border: .1rem solid #EDEDED;
	// box-shadow: -.8rem 4.2rem 5.2rem rgba(0, 0, 0, 0.07);
	border-radius: 1rem;
	color: $white;
	padding: 4rem 4rem 3.5rem 3.2rem;
	position: relative;
	@include bp($point_2) {
		padding: 3.2rem 2.5rem 3rem 2.5rem;
	}
}
.reviews_slide_head {
	margin-bottom: 2.4rem;
	@include bp($point_2) {
		margin-bottom: 2rem;
	}
}
.reviews_slide_name {
	h5 {
		font-size: $m;
		margin-bottom: 1rem;
		@include bp($point_2) {
			margin-bottom: 0.8rem;
		}
	}
}
.reviews_slide_star {
	@include flex-start;
	color: #FBB040;
	li {
		&:not(:last-child) {
			margin-right: 0.23rem;
		}
	}
}

.reviews_slide_txt {
	margin-bottom: 2.4rem;
	overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 2.4rem;
  max-height: 7.2rem;
	
	&.active {
		max-height: 100%;
		-webkit-line-clamp: 10;
	}
	p, ul {
		font-size: $s;
		@include bp($point_2) {
			font-size: 1.6rem;
		}
		&:not(:last-child) {
			margin-bottom: 2rem;
			@include bp($point_2) {
				margin-bottom: 1rem;
			}
		}
	}
}

.reviews_slide_txt--more {
	button {
		font-size: $s;
		color: $white;
		border-bottom: .1rem solid $white;
		cursor: pointer;
		transition: all .3s ease;
		&:hover {
			border-bottom: .1rem solid $trans;
		}
	}
}
.reviews_slide_quote {
	position: absolute;
	bottom: -2rem;
	right: 6.8rem;
	@include flex-center;
	background-color: $orange;
	width: 4rem;
	height: 4rem;
	border-radius: 100%;
	img {
		transition: all .3s ease;
		opacity: 0;
	}
}
.maps {
	@include bp($point_2) {
		order: 2;
	}
}
.contacts {
	@include bp($point_2) {
		order: 0;
	}
}
.maps_main {
	width: 100%;
	border-radius: 1rem;
	overflow: hidden;
	margin-bottom: 9.1rem;
	@include bp($point_2) {
		margin-bottom: 5rem;
	}
	#map {
		height: 57.2rem;
		width: 100%;
		@include bp($point_2) {
			height: 20.3rem;
		}
	}
}
.ymaps-2-1-79-ground-pane {
	filter: grayscale(1);
	-ms-filter: grayscale(1);
	-webkit-filter: grayscale(1);
	-moz-filter: grayscale(1);
	-o-filter: grayscale(1);
}

.contacts_list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(29rem, 1fr));
	grid-gap: 7.9rem;
	margin-bottom: 6.9rem;
	@include bp($point_2) {
		grid-gap: 5.2rem;
		margin-bottom: 5rem;
	}
	&>li {
		@include flex-start;
		align-items: flex-start;
	}
}

.contacts_item_icon {
	width: 4.7rem;
	min-width: 4.7rem;
	@include flex-center;
	margin-right: 3rem;
}

.contacts_item_label {
	p {
		font-size: 2.2rem;
		font-family: $font_2;
	}
	margin-bottom: 1rem;
}

.contacts_item_content {
	li {
		font-size: $s;
		a {
			color: $dark;
			&:hover {
				color: $orange;
			}
		}
	}
}

.messenger_list {
	@include flex-start;
	li {
		@include el-scale;
		&:not(:last-child) {
			margin-right: 2rem;
			a {
				color: #009ED0;
			}
		}
		&:last-child {
			a {
				color: #00AF27;
			}
		}
	}
}


.service_slider_nav {
	display: none;
	@include bp($point_2) {
		@include flex-space;
		// pointer-events: none;
	}
	position: absolute;
	bottom: 6rem;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	div {
		transition: all .3s ease;
		pointer-events: all;
		&:hover {
			svg {
				color: $orange;
			}
		}
	}
}