// Font size variable

$xs: 1.6rem;
$s: 1.8rem;
$m: 2rem;
$l: 2.2rem;
$xl_mob: 3rem;
$xl: 4rem;
$xxl: 5.5rem;

// Global 
$header: 10.9rem;
$footer: 5rem;

// Mobile
$header_mob_fixed: 7.1rem;
$header_mob: 10.5rem;
$footer_mob: 5rem;

$pbm: 8rem;
//---------------------------------------------fonts
$font_path: "../fonts/";
$font_1: 'Gilroy-Regular', sans-serif;
$font_2: 'Gilroy-Medium', sans-serif;
$font_3: 'Gilroy-SemiBold', sans-serif;
$font_size_base: $m;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$black: #000;
$orange: #FF5C00;
$gray: #6C6C6C;
$dark: #282828;
$trans: transparent;


$color_text_base: $dark;
$color_link_base: $orange;
$body_bg: $white;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popup : 101,
	header : 100,
	footer : 100,
	el: -1,

);
//---------------------------------------------z-index###

//---------------------------------------------layout
$page_width: 142rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_radius: 2.4rem;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height: 4rem;
$input_offset: 0 2rem;
$input_font_size: $font_size_base;
$input_border_color: #ccc;
$input_border_color_active: $black;
$input_placeholder_color: rgba($black, .4);
$input_text_color: $black;
$input_radius: $gl_radius;
//---------------------------------------------forms###



