//------------------------------------------------------------layout
.header {
	position: fixed;
	top: 0;
	left: 0;
	height: $header;
	width: 100%;
	will-change: transform;
	@include z-index(header);
	transition: all .3s ease;
	@include bp($point_2) {
		height: $header_mob;
	}
	&.fixed {
		background-color: $dark;
		@include bp($point_2) {
			height: $header_mob_fixed;
			.header_main_row {
				padding: 1.3rem 0;
			}
			.header_logo {
				width: 4rem;
			}
		}
	}
	a {
		color: $white;
	}
}

.header {
}
.section_in {
}
.header_main_row {
	@include flex-space;
	padding: 1.3rem 0;
	transition: all .3s ease;
	@include bp($point_2) {
		position: relative;
		padding: 2.5rem 0;
	}
}
.header_logo {
	width: 7.5rem;
	margin-right: 4rem;
	transition: all .3s ease;
	@include bp($point_2) {
		width: 5rem;
		margin-right: 0;
	}
}
.cover_img {
}
.header_main_nav {
	@include bp($point_2) {
		display: none;
	}
	ul {
		@include flex-start;
		li {
			&.active {
				a {
					color: $orange;
					&:before {
						width: 100%;
					}
				}
			}
			&:not(:last-child) {
				margin-right: 5rem;
			}



			a {
				font-family: $font_2;
				@include link;
				&:hover {
					color: $orange;

				}
				&.active {
					color: $orange;
				}
			}
		}
	}
}
.header_row_right, .header_row_left {
	@include flex-start;
}
.header_main_social {
	@include flex-start;
	margin-right: 5rem;
	@include bp($point_2) {
		display: none
	}
	li {
		@include el-scale;
		a {
			@include flex-center;
		}
		&:not(:last-child) {
			margin-right: 4rem;
			a {
				color: #009ED0;
			}
		}
		&:last-child {
			a {
				color: #00AF27;
			}
		}
	}
}
.icon {
}
.icon-tg {
}
.icon-wa {
}
.header_action_btn {
	@include bp($point_2) {
		display: none;
	}
}

.header_burger_trigger {
	display: none;
	@include bp($point_2) {
		display: block;
		width: 3.5rem;
		height: 3rem;
		position: relative;
	}
	li {
		width: 100%;
		height: .5rem;
		border-radius: 1rem;
		background-color: $white;
		position: absolute;
		top: 0;
		left: 0;
		&:nth-child(1) {

		}
		&:nth-child(2) {
			top: 50%;
			transform: translateY(-50%);
			width: 2.5rem;
		}
		&:nth-child(3) {
			top: auto;
			bottom: 0;
			width: 1.5rem;
		}
	}
}


