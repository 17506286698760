img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	display: block;
}

h1 {
	font-size: $xxl;
	font-family: $font_3;
	font-weight: 600;
	@include bp($point_2) {
		font-size: $xl_mob;
	}
	span {
		font-family: $font_2;
		font-weight: 500;
	}
}
// End
h2 {
	font-size: $xl;
	font-weight: 500;
	font-family: $font_2;
	@include bp($point_2) {
		font-size: 2.8rem;
	}
	span {
		font-family: $font_3;
		font-weight: 600;
	}
}
// End
h3 {
	font-size: $xl;
	font-weight: 500;
	font-family: $font_2;
	@include bp($point_2) {
		font-size: 2.8rem;
	}
	span {
		font-family: $font_3;
		font-weight: 600;
	}
}
// End
h4 {
	font-size: $xl;
	font-weight: 500;
	font-family: $font_2;
	@include bp($point_2) {
		font-size: 2.8rem;
	}
	span {
		font-family: $font_3;
		font-weight: 600;
	}
}
// End
h5 {
	font-size: $xl;
	font-weight: 500;
	font-family: $font_2;
	@include bp($point_2) {
		font-size: 2.8rem;
	}
	span {
		font-family: $font_3;
		font-weight: 600;
	}
}
// End
h6 {

}
// End

a {
	transition: all .3s ease;
}

.mod_title {
	position: relative;
	margin-bottom: 4rem;
	padding-bottom: 2.5rem;
	@include bp($point_2) {
		padding-bottom: 2rem;
		margin-bottom: 5rem;
	}
	&:before {
		position: absolute;
		bottom: 0;
		left: 0;
		content: '';
		width: 4.8rem;
		height: .3rem;
		background-color: $orange;
		border-bottom: .3rem solid;
		color: $orange;
	}
}

.mod_link {
	button, a {
		font-size: 2.2rem!important;
		padding: 2.4rem 5.1rem!important;
		border-radius: 1rem!important;
	}
}
