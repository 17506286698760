//------------------------------------------------------------layout
.footer {
	width: 100%;
	min-height: $footer;
	flex: 0 0 auto;
	will-change: transform;
	position: relative;
}

// Footer main

.footer {
	@include bp($point_2) {
		order: 10;
		background-color: $dark;
		color: $white;
		min-height: 17.8rem;
	}
}
.section_in {
}
.footer_main {
	padding-bottom: 2.9rem;
	@include bp($point_2) {
		padding: 0
	}
}
.footer_list {
	@include flex-start;
	align-items: flex-end;
	@include bp($point_2) {
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 2.5rem 0 4.9rem 0;
	}
	li {
		font-size: $s;
		a {
			color: $dark;
			font-size: $s;
			@include bp($point_2) {
				color: $white;
			}
		}
		&:not(:last-child) {
			margin-right: 14rem;
			@include bp($point_2)  {
				margin-right: 0;
				margin-bottom: 2rem;
			}
		}
		&:nth-child(2) {

			a {
				border-bottom: .1rem solid $dark;
				&:hover {
					border-bottom: .1rem solid $trans;
				}
			}
		}
	}
}
.footer_arkada_item {
	a {
		@include flex-start;
		align-items: flex-end;
		p {
			margin-right: 1rem;
		}
	}
}
.footer_arkada_logo {
	width: 2.7rem;
	@include flex-center;
	align-items: flex-end;
}
