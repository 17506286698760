.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-close {
	font-size: calc(23/10)*1rem;
	width: calc(23/23)*1em;
}
.icon-mobile_next {
	font-size: calc(16/10)*1rem;
	width: calc(10/16)*1em;
}
.icon-mobile_prev {
	font-size: calc(16/10)*1rem;
	width: calc(10/16)*1em;
}
.icon-next {
	font-size: calc(25/10)*1rem;
	width: calc(16/25)*1em;
}
.icon-prev {
	font-size: calc(25/10)*1rem;
	width: calc(16/25)*1em;
}
.icon-star {
	font-size: calc(16/10)*1rem;
	width: calc(18/16)*1em;
}
.icon-tg {
	font-size: calc(23/10)*1rem;
	width: calc(24/23)*1em;
}
.icon-wa {
	font-size: calc(25/10)*1rem;
	width: calc(25/25)*1em;
}
