
// Сброс стилей input
@mixin app {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	background-color: transparent;
	font-family: $font_1;
	font-size: $font_size_base;
}

// Input end


// Flex styles

@mixin flex-center {
	justify-content: center;
	display: flex;
	align-items: center;
}

@mixin flex-col {
	flex-direction: column;
}

@mixin flex-start {
	justify-content: flex-start;
	display: flex;
	align-items: center;
}

@mixin flex-space {
	justify-content: space-between;
	display: flex;
	align-items: center;
}

@mixin flex-end {
	justify-content: flex-end;
	display: flex;
	align-items: center;
}

// End flex styles

// Style default link


@mixin link {
	position: relative;
	padding-bottom: 0.2rem;
	transition: all .3s ease;
	&:before {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0;
		content: '';
		border-bottom: .2rem solid;
		transition: all .3s ease;
		pointer-events: none;
	}
	&:hover {
		&:before {
			width: 100%;
		}
	}
	&.active {
		&:before {
			width: 100%;
		}
	}
}

// End style link

// Global btn

@mixin btn-button {
	position: relative;
	background-color: $orange;
	font-family: $font_1;
	cursor: pointer;
	font-size: $s;
	padding: 1.5rem 3.5rem;
	border-radius: 0.5rem;
	color: $white;
	transition: all .3s ease;
	@include bp($point_2) {
		padding: 2rem 4.4rem!important;
		font-size: $s!important;
	}
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: '';
		box-shadow: .4rem 3.7rem 4.1rem rgba(255, 92, 0, 0.25);
		opacity: 0;
		visibility: hidden;
		transform: scaleY(0);
		transition: all .3s ease;
		pointer-events: none;
		@include z-index(el);
	}
	&:hover {
		background-color: #D14B00;
		&:before {
			opacity: 1;
			visibility: visible;
			transform: scaleY(1);
		}
	}
}



// Btn link


@mixin el-scale {
	transition: all .3s ease;
	&:hover {
		transform: scale(1.1) rotate(10deg);
	}
}

